<template>
	<div>
        <a-select
            size="large"
			show-search
			:dropdownMatchSelectWidth="true"
			:value="valueProductoScroll"
			:default-active-first-option="false"
			:show-arrow="true"
			:filter-option="false"
			:not-found-content="spinnerScroll ? undefined : null"
			@search="handleBusquedaListaProductos"
			@change="handleChangeListaProductos"
			placeholder="Buscar Productos por Codigo, Nombre, Medida, Marca."
			style="width: 100%;"
			id="productoSelect"
			>
			<a-spin v-if="spinnerScroll" slot="notFoundContent" size="large">
				<div class="spin-content">
					<i class="fas fa-search"></i> Obteniedo los Resultados ...
				</div>
			</a-spin>

			<template v-if="config.istotallistaproductoventa == 'ACTIVADO'">
				<a-select-option v-for="(option, i) in lista_producto_scroll" :key="i" :value="option.producto_id">
					{{ option.nombre }}
				</a-select-option>
			</template>

			<template v-if="config.istotallistaproductoventa == 'DESACTIVADO'">
				<template v-if="config.ismarcaizquierdalistaventa == 'ACTIVADO'">
					<a-select-option v-for="(option, i) in lista_producto_scroll" :key="i" :value="option.producto_id">
						{{ option.codigo }} {{ option.marca }} {{ option.nombre }} {{ option.medida }} {{ option.unidad }}
					</a-select-option>
				</template>

				<template v-if="config.ismarcaizquierdalistaventa == 'DESACTIVADO'">
					<a-select-option v-for="(option, i) in lista_producto_scroll" :key="i" :value="option.producto_id">
						{{ option.codigo }} {{ option.nombre }} {{ option.medida }} {{ option.unidad }} {{ option.marca }}
					</a-select-option>
				</template>
			</template>
		</a-select>

		<div v-if="spinnerloading == true" class="col-md-12" style="position: fixed; top: 0; right: 0; bottom: 0; left: 0; background: rgba(0, 0, 0, 0.5); z-index: 1055;">
			<div style="margin-top: 250px;">
				<vue-simple-spinner :line-size="10" size="massive" text-fg-color="#f3f3f3" message="Procesando Información..."></vue-simple-spinner>
				<br><br>
				<center><button type="button" class="btn btn-warning btn-sm" title="Cancelar" @click="spinnerloading = false"><i class="fa fa-stop"></i> Cerrar Ventana de Espera</button></center>
			</div>
		</div>
	</div>
</template>

<script>
	import * as utils from '@/utils/utils'
	import { mapGetters, mapActions } from 'vuex'
    import { 
		STORAGE_LISTA_SCROLL_PRODUCTO
	} from '@/utils/constants'
	import * as database from '@/utils/database'

	function escapeRegExp (string) {
		return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
	}

	export default {

        props: {
			almacenid: { type: Number, required: true },
			tipocambioid: { type: Number, required: true },
			listacambio: { type: Array, required: true }
		},

		data () {
			return {
                utils,
				spinnerloading: false,
                valueProductoScroll: undefined,
                lista_producto_scroll: [],
                spinnerScroll: false,
                loading: false,
				productoLocalStorage: []
			}
		},

		mounted () {
			this.preCargaDeProductos()
        },

		computed: {
            ...mapGetters('configuracion', ['config'])
        },

		methods: {

			preCargaDeProductos () {
				if (localStorage.getItem('cargaDeProductosSinStock') === 'CARGADO') {
					this.cargaDeProductosOffline()
				}

				const interval2 = setInterval(() => {
					if (localStorage.getItem('cargaDeProductosSinStock') === 'CARGADO') {
						this.cargaDeProductosOffline()
					}
				}, 2000)

				setInterval(() => {
					clearInterval(interval2)
				}, 10000)
			},

			async verificarProductos () {
				console.log('verificarProductos')
				this.spinnerloading = true
				this.preCargaDeProductos()
			},

			async cargaDeProductosOffline () {
				try {
					console.log('cargaDeProductos', 'Obteniendo productos offline desde IndexedDB sin Stock')
					const listaproductos = await this.obtenerDatosDeIndexedDB()
					if (listaproductos.length > 150) {
						this.lista_producto_scroll = listaproductos.slice(-100).reverse()
					} else {
						this.lista_producto_scroll = listaproductos
					}
					this.spinnerloading = false
				} catch (error) {
					console.error('Error al obtener la información:', error)
				}
			},

			async obtenerDatosDeIndexedDB () {
				return await new Promise((resolve, reject) => {
					database.getData(STORAGE_LISTA_SCROLL_PRODUCTO)
					.then(array => {
						resolve(array)
					})
					.catch(error => {
						console.error('Error al obtener el array de IndexedDB:', error)
						reject(error) // Rechaza la promesa con el error obtenido
					})
				})
			},

			async handleBusquedaListaProductos (value) {
				if (value.length > 2) {
					console.log('Búsqueda interna de productos para cotización y consultas')
					try {
						const listaproductos = await this.obtenerDatosDeIndexedDB()
						if (!listaproductos || listaproductos.length === 0) {
							utils.openNotificationWithIcon('info', '¡Atención!', 'La lista de productos está vacía. Por favor, vuelve a cargarla.', 'topRight')
							return
						}

						const escapedValue = escapeRegExp(value)
						const regex = new RegExp(escapedValue, 'giu')
						const resultado = listaproductos.filter(element => element.detalle.match(regex))

						if (resultado.length > 150) {
							this.lista_producto_scroll = resultado.slice(-100).reverse()
						} else {
							this.lista_producto_scroll = resultado
						}
					} catch (error) {
						console.error('Error al obtener la información:', error)
					}
				}
			},

			async handleChangeListaProductos (value) {
                const opciones = {
                    productoID: value,
                    almacenID: this.almacenid,
                    listaTipoCambio: this.listacambio,
                    tipocambioID: this.tipocambioid,
                    compraDecimal: this.config.compradecimales
                }

                const producto = await this.buscarProductoEnLista(opciones)

                if (!producto) {
                    this.mostrarAlertaProductoNoEncontrado()
                    return
                }
				
                const data = await this.crearProducto(producto, opciones)
                this.$emit('successScrollProductoCompra', data)
            },

            async buscarProductoEnLista ({ productoID, almacenID }) {
				try {
					const listaproductos = await this.obtenerDatosDeIndexedDB()
					const producto = listaproductos.find(producto => producto.producto_id === parseInt(productoID) && producto.almacenId === parseInt(almacenID))
					
					if (this.config.istotallistaproductoventa === 'ACTIVADO') {
						this.valueProductoScroll = `${producto.nombre}`
					} else if (this.config.istotallistaproductoventa === 'DESACTIVADO') {
						if (this.config.ismarcaizquierdalistaventa === 'ACTIVADO') {
							this.valueProductoScroll = `${producto.codigo} ${producto.nombre} ${producto.medida} ${producto.unidad} ${producto.marca}`
						} else if (this.config.ismarcaizquierdalistaventa === 'DESACTIVADO') {
							this.valueProductoScroll = `${producto.codigo} ${producto.marca} ${producto.nombre} ${producto.medida} ${producto.unidad}`
						}
					}

					return producto
				} catch (error) {
					console.error('Error al obtener la información:', error)
				}
			},

            async crearProducto (producto, opciones) {
                return {
                    producto_id: producto.producto_id,
                    codigo: producto.codigo,
                    nombre: producto.nombre,
                    medida: producto.medida,
                    marca: producto.marca,
                    saldoFisico: producto.saldoFisico,
                    porcentajeUtilidad: producto.porcentajeUtilidad,
                    elaboracion: producto.elaboracion,
                    vencimiento: producto.vencimiento,
                    proveedorID: producto.proveedorID,
					proveedor: producto.proveedor,
                    descuentoCompra: utils.formatMoney(producto.descuentoCompra, opciones.compraDecimal, '.', ''),
                    almacenId: producto.almacenId,
                    precioCompra: utils.formatMoney(producto.precioCompra, opciones.compraDecimal, '.', ''),
                    cantidad: 1,
                    subtotal: utils.formatMoney(parseInt(producto.cantidad) * parseFloat(producto.precioCompra), opciones.compraDecimal, '.', ''),
                    importeTotalMoneda: utils.formatMoney(parseInt(producto.cantidad) * parseFloat(producto.precioCompra), opciones.compraDecimal, '.', '')
                }
            },

			mostrarAlertaProductoNoEncontrado () {
                utils.openNotificationWithIcon('info', 'Mensaje : ', 'No existe el Producto Seleccionado en nuestra Sucursal o el Producto no tiene stock en el almacen !!!', 'topRight')
            },

			mostrarAlertaPrecioCero () {
				utils.openNotificationWithIcon('info', 'Mensaje : ', 'Por favor agregue Precios de Ventas al Producto segun la Sucursal donde pertenezca...', 'topRight')
			}
		}

	}
</script>

<style>
.ant-select-selection-selected-value {
    float: left !important;
    max-width: 100% !important;
    overflow: hidden !important;
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
}
</style>