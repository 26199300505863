<template>
    <div v-if="$can('adquisicion.menu.nueva.compra')">
        <a-row type="flex" :gutter="[8,8]">
            <a-col class="flex-card-left" flex="1 1 75%">
                <a-row type="flex" :gutter="[8,8]">
                    <a-col flex="1 0 100%">
                        <a-button 
                            v-if="$can('adquisicion.boton.opciones.de.compra')" 
                            size="small" 
                            @click="habilitarOpciones()"
                            >
                            <span v-if="!isBlock"><a-icon type="lock" /> Habilitar</span>
                            <span v-if="isBlock"><a-icon type="unlock" /> Deshabilitar</span>
                        </a-button>
                        <a-button 
                            v-if="$can('adquisicion.busqueda.de.producto.en.compras')"
                            size="small" 
                            @click="openModalBuscarProducto()"
                            >
                            <a-icon type="search" /> Buscar Producto
                        </a-button>
                        <template v-if="estadomodulocompra === 'AGREGAR'">
                            <a-button 
                                size="small" 
                                type="primary" 
                                @click="openVentanaNueva()"
                                >
                                <a-icon type="desktop" /> Nueva Ventana {{ nuevaventanacompraid }}
                            </a-button>
                            
                            <a-button 
                                v-if="$can('adquisicion.agregar.nuevo.producto.compra')"
                                size="small" 
                                type="primary" 
                                @click="openModalProducto(null, 'agregar')"
                                >
                                <a-icon type="plus" /> Nuevo Producto
                            </a-button>
                        </template>
                    </a-col>
                </a-row>

                <a-row type="flex" :gutter="[2,2]">
                    <a-col flex="1 0 30%" v-if="$can('adquisicion.compra.por.fechas')">
                        <span class="texto-base-12"><strong>Elige la fecha:</strong></span>
                        <a-date-picker 
                            :disabled="!isBlock" 
                            :value="utils.getDateFormat(consulta.fechadecompra)" 
                            @change="onChangeFechaCompra" 
                            :locale="locale"
                            size="large"
                            style="width: 100%"
                        />
                    </a-col>
                    
                    <a-col flex="1 0 20%" v-if="$can('adquisicion.compra.por.usuarios')">
                        <!--SELECT USUARIO-->
                        <span class="texto-base-12"><strong>Usuario:</strong></span>
                        <a-select 
                            :disabled="!isBlock" 
                            :value="consulta.usuario" 
                            @change="onChangeUsuario"
                            size="large"
                            style="width: 100%"
                            >
                            <a-select-option v-for="(value, index) in usuarios" :key="index" :value="value.usuario"> {{ value.usuario }}</a-select-option>
                        </a-select>
                        <!--SELECT USUARIO-->
                    </a-col>

                    <a-col flex="1 0 20%" v-if="$can('adquisicion.compra.por.sucursales')">
                        <!--SELECT ALMACEN-->
                        <span class="texto-base-12"><strong>Sucursal:</strong></span>
                        <a-select 
                            :disabled="!isBlock" 
                            :value="consulta.almacen_id" 
                            @change="onChangeAlmacen"
                            size="large" 
                            style="width: 100%"
                            >
                            <a-select-option v-for="(value, index) in almacenes" :key="index" :value="value.almacen_id"> {{ value.nombre }}</a-select-option>
                        </a-select>
                        <!--SELECT ALMACEN-->
                    </a-col>

                    <a-col flex="1 0 20%">
                        <span class="texto-base-12"><strong>Tipo Compra:</strong></span>
                        <a-select 
                            :value="consulta.plancuenta_id" 
                            @change="onChangeTipoCompra" 
                            style="width: 100%"
                            size="large"
                            >
                            <a-select-option v-for="(value, index) in tipodecompras" :key="index" :value="value.plancuenta_id"> {{ value.nombre_corto }}</a-select-option>
                        </a-select>
                    </a-col>

                    <a-col flex="1 0 35%">
                        <label class="texto-base-12">Proveedor: <span class="texto-10"> Obligatorio *</span></label>
                        &nbsp;&nbsp;
                        <a href="#" size="small" type="primary" class="texto-14" @click.prevent="abriModalSecundary()"><a-icon type="plus" :style="{ fontSize: '18px', color: '#fffff' }" />  Agregar Proveedor</a>
                        <a-select  size="large" :value="consulta.proveedor_id" @change="onChageScrollProveedor" placeholder="Seleccionar" show-search option-filter-prop="children" :filter-option="filterOptionProveedor" style="width: 100%">
                            <a-select-option v-for="(value, index) in proveedores" :key="index" :value="value.proveedor_id"> {{ value.empresa }}</a-select-option>
                        </a-select>
                    </a-col>

                    <a-col flex="1 0 15%">
                        <span class="texto-base-12"><strong>Nro.Comprobante:</strong></span>
                        <a-input size="large" v-model="consulta.nrodocumento" placeholder="0" onfocus="myFunction(this)"/>
                    </a-col>

                    <a-col flex="1 0 40%">
                        <span class="texto-base-12"><strong>Observaciones:</strong></span>
                        <a-textarea v-model="consulta.observaciones" placeholder="Observaciones de Compra" :rows="1" />
                    </a-col>
                </a-row>

                <a-row type="flex" :gutter="[2,2]">
                    <a-col class="flex-card-codigo" flex="1 0 25%">
                        <center>
                            <label>CODIGO BARRAS: 
                                <span style="font-weight:700; font-size:16px; color: rgb(32, 80, 129);">(F1)</span>
                            </label>
                        </center>
                        <CodigoBarrasCompra
                            :almacenid="1"
                            :tipocambioid="0"
                            :listacambio="[]"
                            @successProductoCodigoCompra="successProductoCodigo"
                        />      
                    </a-col>

                    <a-col class="flex-card-producto" flex="1 0 65%">
                        <center>
                            <label>SELECCIONA PRODUCTOS PARA LA COMPRA: 
                                <span style="font-weight:700; font-size:16px; color: rgb(32, 80, 129);">(F2)</span>
                            </label>
                        </center>
                        <ScrollProductoCompra
                            ref="scrollProducto" 
                            :almacenid="parseInt(consulta.almacen_id)"
                            :tiposalidaid="1"
                            :tipocambioid="0"
                            :listacambio="[]"
                            @successScrollProductoCompra="successProducto"
                        />
                    </a-col>

                    <a-col class="flex-card-producto" flex="1 0 5%" style="top: 6px;">
                        <label class="control-label">Sync:</label><br>
                        <a-button size="large" @click="obtenerListadoActual">
                            <a-icon type="reload" />
                        </a-button>
                    </a-col>

                    <a-col class="flex-card-tabla" flex="1 1 100%">
                        <TablaProductosCompra
                            ref="tablaproductoscompra"
                            :estadomodulocompra="estadomodulocompra"
                            :nuevaventanacompraid="nuevaventanacompraid"
                            :listaproducto="productoElegido"
                            :almacenID="parseInt(consulta.almacen_id)"
                            :proveedorID="parseInt(consulta.proveedor_id)"
                            @subtotal="successSubtotal"
                            @descuento="successDescuento"
                            @total="successTotal"
                            @productos="successTablaProducto"
                            @proveedorID="successProveedorID"
                        />
                    </a-col>
                </a-row>
            </a-col>
            
            <a-col class="flex-card-right" flex="1 0 25%">
                <div style="height: 100px; background-color:#000000; overflow: auto;">
                    <center><label style="color:#fff;">Precio Total</label></center>
                    <center><font size="7" color="#0ed104"><strong><p>{{ ViewSubtotal }}</p></strong></font></center>
                </div>

                <template>
                    <div style="height: 100px; background-color:#000000; overflow: auto;" v-if="this.consulta.descuento > 0">
                        <center><label style="color:#fff;">Precio con Descuento</label></center>
                        <center><font size="7" color="#ddf7ff"><strong><p>{{ ViewSubtotalTotalConDescuento }}</p></strong></font></center>
                    </div>
                </template>

                <template>
                    <div style="height: 100px; background-color:#000000; overflow: auto;" v-if="this.consulta.descuentoMonedaMonto > 0">
                        <center><label style="color:#fff;">Precio Total con Descuento</label></center>
                        <center><font size="7" color="#ddf7ff"><strong><p>{{ ViewTotalConDescuento }}</p></strong></font></center>
                    </div>
                </template>

                <h4 style="color:black; font-size:16px; font-weight:700;"><center>NRO DE COMPRA</center></h4>
                <h2 class="codigodeventa">{{ consulta.codigoCompra }}</h2>

                <div>
                    <label>Descuento {{ config.monedasimbolo }}:</label>
					<a-input-number
						size="large"
						:min="1" 
						:max="1000000" 
						:value="consulta.descuentoMonedaMonto" 
						@change="onChangeDescuentoMoneda"
						placeholder="0.00"
                        style="width: 100%"
					/>
                </div>

                <div class="row" v-if="habilitarcredito == true">
					<div class="col-md-12">
                        <center><label class="texto-base-12">FECHA PAGO DE CREDITO</label></center>
						<strong>
							<a-date-picker
								size="large"
								:value="utils.getDateFormat(consulta.fechaCredito)" 
								@change="onDateCredito"
								:locale="locale" 
								style="width:100%; height:44px; font-size:14px;  border-width:2px; border-color:orange; border-style:solid;"
							/>
						</strong><br>
					</div>
				</div>

                <br>

                <template v-if="estadomodulocompra === 'AGREGAR'">
					<div class="box" v-if="habilitarcredito == false">
						<button 
                            type="button" 
                            id="EnviarInformacion" 
                            class="btn btn-block btn-lg" 
                            style="background-color:var(--element);" 
                            @click.once="realizarCompra()"
                            :key="buttonKeyCompra"
                            >
                            <font size=4>
                                <center>
                                    <strong style="color:white;">REGISTRAR INGRESO<span style="font-size:14px;">(F9)</span></strong>
                                </center>
                            </font>
                        </button>
					</div>

					<div class="box" v-if="habilitarcredito == true">
						<button 
                            type="button" 
                            id="EnviarInformacion" 
                            class="btn btn-block btn-lg" 
                            style="background-color:var(--element);"   
                            @click.once="realizarCompra()"
                            :key="buttonKeyCredito" 
                            >
                            <font size=4>
                                <center>
                                    <strong style="color:white;">REALIZAR CREDITO <span style="font-size:14px;">(F9)</span></strong>
                                </center>
                            </font>
                        </button>
					</div>
                </template>

                <template v-if="estadomodulocompra === 'EDITAR'">
                    <div class="box" v-if="habilitarcredito == false">
                        <button 
                            type="button" 
                            class="btn btn-block btn-lg" 
                            style="background-color:#53ad03;" 
                            @click.once="editarVenta()"
                            :key="buttonKeyEditarVenta"
                            >
                            <font size=4>
                                <center>
                                    <strong style="color:white;">EDITAR COMPRA <span style="font-size:14px;">(F9)</span></strong>
                                </center>
                            </font>
                        </button>
                    </div>
                </template>

                <div class="box">
					<button 
                        type="button" 
                        id="EnviarInformacion" 
                        class="btn btn-block btn-lg" 
                        style="background-color:#ff3333;" 
                        @click="eliminarListaProductos()" 
                        >
                        <font size=2>
                            <center>
                                <strong style="color:white;">ACTUALIZAR LISTA DE ITEMS</strong>
                            </center>
                        </font>
                    </button>
				</div>
            </a-col>
        </a-row>

        <!--PRODUCTO PROVEEDOR-->
		<a-modal
            v-model="modalBuscarProducto"
            :title="false"
            :closable="false"
            :destroyOnClose="true"
            :maskClosable="false"
            :dialog-style="{ top: '5px' }"
            :width="1330"
            :zIndex="1040"
            >

            <div class="row mb-12">
                <ModuloProductoProveedor 
                    :utilidadporcentaje="config.utilidadporcentaje"
                    :statecodigobarradefault="config.statecodigobarradefault" 
                    :disabledvencimiento="config.disabledvencimiento" 
                    :preciocifradecimal="config.preciocifradecimal" 
                    :preciocifraentera="config.preciocifraentera" 
                    :precioreglatres="config.precioreglatres"  
                    :sessionuserid="config.userid"
                    :monedasimbolo="config.monedasimbolo"
                    :almacenid="config.almacenid"			
                /> 
            </div>

            <template slot="footer">
                <a-button key="back" @click="closeModalBuscarProducto()">
                    CERRAR MODAL
                </a-button>
            </template>
        </a-modal>
        <!--PRODUCTO PROVEEDOR-->

        <!--PRODUCTOS-->
		<a-modal
            v-model="modalProducto"
            title="GESTION DE PRODUCTO"
            :closable="true"
            :destroyOnClose="true"
            :maskClosable="false"
            :dialog-style="{ top: '5px' }"
            :width="480"
            :zIndex="1049"
            >

            <div class="row mb-12">
                <ComponenteProducto
                    :tipoproducto="producto.tipomodal"
                    :dataproducto="producto.producto"
                    :almacenid="parseInt(consulta.almacen_id)"
                    :proveedorid="consulta.proveedor_id"
                    :generarlenghtcodigobarra="config.generarlenghtcodigobarra"
                    @successProducto="dataSuccessProducto"
                />
            </div>

            <template slot="footer">
                <a-button key="back" @click="modalProducto = false">
                    CERRAR MODAL
                </a-button>
            </template>
        </a-modal>
        <!--PRODUCTOS-->

        <!--PROVEEDORES-->
        <a-modal
            v-model="modalProveedor"
            title="GESTION DE PROVEEDOR"
            :closable="true"
            :destroyOnClose="false"
            :maskClosable="false"
            :width="420"
            :zIndex="1051"
            >
    
            <div class="row mb-12">
                <div class="form-group">
                    <label>Nombre: <span style="font-size:11px; font-weight:700; color:#05729b;"> Obligatorio *</span></label>
                    <a-input size="large" v-model="consulta.nombreProveedor" placeholder="Nombre del Proveedor" onfocus="myFunction(this)"/>
                </div>
            </div>
    
            <template slot="footer">
                <a-button key="back" @click="modalProveedor = false">
                    CANCELAR
                </a-button>
                <a-button @click.once="guardarProveedor()" :key="buttonKeyProveedor" type="primary">GUARDAR PROVEEDOR</a-button>
            </template>
        </a-modal>

        <div v-if="spinnerloading == true" class="col-md-12" style="position: fixed; top: 0; right: 0; bottom: 0; left: 0; background: rgba(0, 0, 0, 0.5); z-index: 1055;">
			<div style="margin-top: 250px;">
				<vue-simple-spinner :line-size="10" size="massive" text-fg-color="#f3f3f3" message="Procesando Informacion..."></vue-simple-spinner>
				<br><br>
				<center><button type="button" class="btn btn-warning btn-sm" title="Cancelar" @click="spinnerloading = false"><i class="fa fa-stop"></i> Cerrar Ventana de Espera</button></center>
			</div>
		</div>
    </div>
</template>

<script>
	import moment from 'moment'
    import Swal from 'sweetalert2'
	import * as utils from '@/utils/utils'
    import Cookies from 'js-cookie'
    import { mapGetters, mapActions } from 'vuex'
	import locale from 'ant-design-vue/es/date-picker/locale/es_ES'
    import 'moment/locale/es'
    import ScrollProductoCompra from '../../../components/ScrollProductoCompra.vue'
    import CodigoBarrasCompra from '../../../components/CodigoBarrasCompra.vue'
    import TablaProductosCompra from '../../../components/TablaProductosCompra.vue'
    import ComponenteProducto from '../../Inventario/Components/ComponenteProducto.vue'
	import ModuloProductoProveedor from '../../Inventario/Modulos/ModuloProductoProveedor.vue'
    import SelectScrollProveedor from '../../../components/SelectScrollProveedor.vue'

    import {
        ALMACEN_SISTEMA_KEY_ID,
        SISTEMA_USUARIO
    } from '@/utils/constants'

    export default {

        props: [
            'nuevaventanacompraid', 
            'estadomodulocompra',
            'updatecompra'
        ],

        components: {
            ScrollProductoCompra,
            CodigoBarrasCompra,
            TablaProductosCompra,
            ModuloProductoProveedor,
            ComponenteProducto
        },

        data () {
            return {
                dateFormat: 'YYYY-MM-DD',
                moment,
                locale,
                utils,
                productoElegido: {},
                consulta: {
                    almacen_id: '',
                    plancuenta_id: 0,
                    proveedor_id: 0,
                    tipoprecioventaID: '1',
                    usuario: 'Seleccionar',
                    tiposalida_id: 1,
                    tipo_venta_id: '1',
                    observaciones: '',
                    fechaCredito: '',
                    fechadecompra: '',
                    productos: [],
                    subtotal: '',
                    descuento: '',
                    total: '',
                    importeTotal: '',
                    descuentoPorcentajeManual: 'Seleccionar Descuento',
                    descuentoPorcentajeSeleccion: '',
                    descuentoPorcentajeNombre: '',
                    descuentoMonedaMonto: '',
                    clienteID: '',
                    clienteNombre: 'SIN NOMBRE',
                    clienteNit: '0',
                    codigoCompra: '',
                    importePagar: '',
                    cambio: '',
                    nrodocumento: 0,
                    nombreProveedor: ''
                },
                producto: {
                    producto: {},
                    tipomodal: ''
                },
                isBlock: false,
                spinnerloading: false,
                habilitardescuentomanualventa: false,
                habilitarcredito: false,
                modalCliente: false,
                modalBuscarProducto: false,
                modalProducto: false,
                buttonKeyCompra: 100,
                buttonKeyCredito: 200,
                buttonKeyCliente: 300,
                buttonKeyProveedor: 400,
                isDisabledButton: this.$can('manager.arqueo.de.caja'),
                modalProveedor: false
            }
        },

        created () {
            this.cargarAlmacenProductos()
            this.getAlmacenListar()
            this.getProveedores()
            this.cargarUsuarios()
            this.cargarDatosCompra()
            this.cargarTipoCompra()
            this.comandkeys()
            this.$root.$on('llamarResetDescuendoMoneda', this.resetDescuentoMonedaMonto)
        },

        computed: {
            ...mapGetters('almacen', ['almacenes']),
            ...mapGetters('usuario', ['usuarios']),
            ...mapGetters('auth', ['user']),
            ...mapGetters('configuracion', ['config']),
            ...mapGetters('tipoprecioventa', ['listatipoprecioventa']),
            ...mapGetters('plancuenta', ['tipodecompras']),
            ...mapGetters('proveedor', ['proveedores']),

            totalConDescuento () {
                if (this.consulta.descuentoMonedaMonto > 0) {
                    return parseFloat(this.consulta.total) - parseFloat(this.consulta.descuentoMonedaMonto)
                }

                return parseFloat(this.consulta.subtotal)
            },

            ViewSubtotal () {
                return utils.formatMoney(this.consulta.subtotal, this.config.compradecimales, '.', '')
            },

            ViewSubtotalTotalConDescuento () {
                return utils.formatMoney(this.consulta.total, this.config.compradecimales, '.', '')
            },

            ViewTotalConDescuento () {
                return utils.formatMoney(this.totalConDescuento, this.config.compradecimales, '.', '')
            }
        },

        methods: {
            ...mapActions('producto', ['getListaScrollMasivoProducto']),
            ...mapActions('almacen', ['getAlmacenListar']),
            ...mapActions('usuario', ['getUsuarioListar']),
            ...mapActions('plancuenta', ['getTipoCompras']),
            ...mapActions('compra', ['getDatosCompra', 'guardarCompra']),
            ...mapActions('proveedor', ['getProveedorListado', 'guardarProveedorRapido']),

            filterOptionProveedor (input, option) {
                return (
                    option.componentOptions.children[0].text.toUpperCase().indexOf(input.toUpperCase()) >= 0
                )
            },

            comandkeys () {
				var self = this

				var ENTER_KEY_CLOSE = 27
				var ENTER_KEY_SUB_CLOSE = 27
				var ENTER_KEY_CONFIRMAR = 13

				var ENTER_KEY_CODIGO_BARRA = 112
				var ENTER_KEY_LISTA = 113
				var ENTER_KEY_IMPORTE = 114
				var ENTER_KEY_CLIENTE = 115
				var ENTER_KEY_TIPO_SALIDA = 116
				var ENTER_KEY_AGREGAR_CLIENTE = 117
				var ENTER_KEY_AGREGAR_SOLICITANTE = 118
				var ENTER_KEY_GUARDAR_CLIENTE = 119
				var ENTER_KEY_GUARDAR_SALIDA = 120
				var ENTER_KEY_MAYUS = 16
				var ENTER_KEY_O = 79
				var ENTER_KEY_J = 74
				var ENTER_KEY_CTRL = 17
				var ENTER_KEY_PLUS = 61

				document.addEventListener('keydown', function (e) {
                    const code = e.keyCode || e.which
                    const habilitarControlCodigoBarras = self.config.habilitarcontrolcodigobarras === 'ACTIVADO'
                    const codesToPrevent = [74, 61, 17, 27, 112, 113, 114, 115, 116, 117, 118, 119, 120]

                    if ((habilitarControlCodigoBarras && codesToPrevent.includes(code)) || (!habilitarControlCodigoBarras && codesToPrevent.slice(1).includes(code))) {
                        e.preventDefault()
                        
                        switch (code) {
                            case ENTER_KEY_TIPO_SALIDA:
                                console.log('F5')
                                break
                            case ENTER_KEY_J:
                            case ENTER_KEY_PLUS:
                            case ENTER_KEY_CTRL:
                            case ENTER_KEY_CODIGO_BARRA:
                                document.getElementById('CodigoBarrasOnfocus').focus()
                                break
                            case ENTER_KEY_CLOSE:
                                document.getElementById('CodigoBarrasOnfocus').focus()
                                document.getElementById('CerrarModalCliente').click()
                                break
                            case ENTER_KEY_SUB_CLOSE:
                                document.querySelector('.swal2-cancel').click()
                                break
                            case ENTER_KEY_CONFIRMAR:
                                document.querySelector('.swal2-confirm').click()
                                document.getElementById('CodigoBarrasOnfocus').dispatchEvent(new KeyboardEvent('keypress'))
                                break
                            case ENTER_KEY_GUARDAR_SALIDA:
                                document.getElementById('EnviarInformacion').click()
                                break
                            case ENTER_KEY_LISTA:
                                document.getElementById('productoSelect').click()
                                break
                            case ENTER_KEY_IMPORTE:
                                document.getElementById('ImportePagar').focus()
                                document.getElementById('ImportePagar').select()
                                break
                            case ENTER_KEY_CLIENTE:
                                break
                            case ENTER_KEY_AGREGAR_SOLICITANTE:
                                document.getElementById('InputSolicitante').focus()
                                break
                            case ENTER_KEY_AGREGAR_CLIENTE:
                                document.getElementById('AgregarCliente').click()
                                break
                            case ENTER_KEY_GUARDAR_CLIENTE:
                                document.getElementById('GuardarCliente').click()
                                break
                            default:
                                break
                        }
                    }
                })
			},

            habilitarOpciones () {
                this.isBlock = !this.isBlock
            },

            cargarAlmacenProductos () {
				this.consulta.almacen_id = (this.user) ? this.user.almacenID.toString() : Cookies.get(ALMACEN_SISTEMA_KEY_ID)
            },

            cargarUsuarios () {
				this.consulta.usuario = (this.user) ? this.user.usuario : Cookies.get(SISTEMA_USUARIO)
				if (this.$can('adquisicion.compra.por.usuarios')) {
					this.getUsuarioListar()
				}
            },

            getProveedores () {
                this.getProveedorListado()
            },

            cargarProductosMasivos () {
                this.consulta.proveedor_id = '0'
                this.getListaScrollMasivoProducto(this.consulta)
            },

            cargarDatosCompra () {
                this.getDatosCompra(this.consulta)
                .then(response => { 
                    if (response.status === 200) {
						if (this.estadomodulocompra === 'AGREGAR') {
							this.consulta.codigoCompra = response.data.codigoCompra
						} else if (this.estadomodulocompra === 'EDITAR') {
							this.consulta.codigoCompra = this.updatecompra.codigoCompra
						}
					}
                }).catch(error => {
                    utils.openNotificationWithIcon('error', 'Mensaje:', utils.showErrors(error.response.data), 'topRight')
                })
            },

            cargarTipoCompra () {
                this.getTipoCompras()
                .then(response => { 
                    this.consulta.plancuenta_id = '10'
                }).catch(error => {
                    utils.openNotificationWithIcon('error', 'Mensaje:', utils.showErrors(error.response.data), 'topRight')
                })
            },
            // Seleccion de Datos

            onChangeFechaCompra (date, dateString) {
				this.consulta.fechadecompra = dateString
			},

            onChangeUsuario (value) {
				this.consulta.usuario = value
            },

            onChangeAlmacen (value) {
				this.consulta.almacen_id = value
                this.cargarProductosMasivos()

                setTimeout(function () { 
                    this.$refs.scrollProducto.verificarProductos()
                    this.$refs.tablaproductoscompra.verificarSucursal(this.consulta.almacen_id)
                }.bind(this), 700)
            },

            onChangeTipoCompra (value) {
                this.consulta.plancuenta_id = value

                if (this.consulta.plancuenta_id === '11') {
					this.habilitarcredito = true
				} else {
					this.habilitarcredito = false
				}
            },

            onDateCredito (date, dateString) {
                this.consulta.fechaCredito = dateString
            },

            // Resultado de Datos
            successProducto (value) {
                this.productoElegido = value
                this.$refs.tablaproductoscompra.agregarProductoALaLista(value)
            },

            successProductoCodigo (value) {
                this.productoElegido = value
                this.$refs.tablaproductoscompra.agregarProductoALaLista(value)
            },

            successSubtotal (value) {
                this.consulta.subtotal = value
            },

            successDescuento (value) {
                this.consulta.descuento = value
            },

            successTotal (value) {
                this.consulta.importePagar = utils.formatMoney(value, this.config.compradecimales, '.', '')
                this.consulta.total = value
            },

            successTablaProducto (value) {
                this.consulta.productos = value
            },

            onChageScrollProveedor (value) {
                this.consulta.proveedor_id = value
                this.getListaScrollMasivoProducto(this.consulta)

                setTimeout(function () { 
                    this.$refs.scrollProducto.verificarProductos()
                    this.$refs.tablaproductoscompra.verificarProveedor(this.consulta.proveedor_id)
                    this.$refs.tablaproductoscompra.verificarSucursal(this.consulta.almacen_id)
                }.bind(this), 700)
            },

            successProveedorID (value) {
                this.consulta.proveedor_id = value.toString()
            },

            onChangeDescuentoMoneda (value) {
                this.consulta.descuentoMonedaMonto = value
            },
            // Modales
            openVentanaNueva () {
				const ventana = parseInt(this.nuevaventanacompraid) + 1
				this.$emit('abrirNuevaVentana', ventana)
			},

            openModalBuscarProducto () {
				setTimeout(function () { 
                    this.modalBuscarProducto = true
                }.bind(this), 300)
			},

            closeModalBuscarProducto () {
                this.showModalIframeProducto = true
				this.cargarAlmacenProductos()
                this.cargarProductosMasivos()
                this.cargarDatosCompra()

				setTimeout(function () {
                    this.modalBuscarProducto = false
                    this.showModalIframeProducto = false
                }.bind(this), 500)
			},

            openModalProducto (data, tipo) {
                this.producto.producto = []
                this.producto.tipomodal = ''

                setTimeout(function () { 
                    this.producto.producto = data
                    this.producto.tipomodal = tipo
                    this.modalProducto = true
                }.bind(this), 300)
            },

            dataSuccessProducto (newValue) {
                this.cargarAlmacenProductos()
                this.cargarProductosMasivos()
                this.cargarDatosCompra()
                this.$refs.scrollProducto.verificarProductos()
                
                setTimeout(function () { 
                    this.modalProducto = false
                }.bind(this), 300)
            }, 
            // Operaciones

            obtenerListadoActual (value) {
                this.spinnerloading = true
                this.cargarProductosMasivos()

                setTimeout(function () { 
                    this.$refs.scrollProducto.verificarProductos()
                    this.spinnerloading = false
                }.bind(this), 700)
            },

            realizarCompra () {
				if (Object.entries(this.consulta.productos).length === 0) {
                    Swal.fire('Atencion!', 'Por favor seleccione un producto para realizar la compra y vuelva a intentar...', 'error')
                } else if (parseInt(this.consulta.proveedor_id) === 0) {
                    Swal.fire('Atencion!', 'Por favor seleccione un proveedor para realizar la compra y vuelva a intentar...', 'error')
                } else if (this.consulta.subtotal === 0) {
                    utils.openNotificationWithIcon('warning', 'Mensaje : ', 'Por favor ingrese una Cantidad y un Precio de Compra...', 'topRight')
                    this.buttonKey++
                } else {
                    Swal.fire({
                        title: 'Desea Agregar y Guardar?',
                        text: 'Tome en cuenta que debera registrar con cuidado los campos ingresados y no debe contener datos duplicados en el sistema.',
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Si, Proceder Ahora (INTRO)',
                        cancelButtonClass: 'btn btn-danger',
                        confirmButtonClass: 'btn btn-success',
                        cancelButtonText: 'No, Proceder! (ESC)'
                    }).then((result) => {
                        if (result.value) {
							this.spinnerloading = true
							this.consulta.importeTotal = this.totalConDescuento
                            this.guardarCompra(this.consulta)
                            .then(response => {
                                if (response.status === 201) {
                                    this.$refs.tablaproductoscompra.vaciarListaProductos()
                                    this.cargarDatosCompra()
                                    this.cargarProductosMasivos()

                                    setTimeout(function () {
                                        this.consulta.fechaCredito = ''
                                        this.consulta.proveedor_id = '0'
                                        
                                        this.$refs.scrollProducto.verificarProductos()
                                        this.$refs.tablaproductoscompra.verificarSucursal(this.consulta.almacen_id)

                                        const compraID = parseInt(response.data.compraID)
                                        if (compraID) {
                                            if (response.data.comprobanteTermico === 'ACTIVADO') {
                                                utils.openReciboMobile(`/reporte/compra/termica/${compraID}`)
                                            } else {
                                                utils.openReciboMobile(`/reporte/compra/reporte/carta/${compraID}`)
                                            }
                                        }

                                        this.spinnerloading = false
                                    }.bind(this), 500)
                                }
                            })
                            .catch(error => {
                                utils.openNotificationWithIcon('error', 'Mensaje:', utils.showErrors(error.response.data), 'topRight')
                            })
                        } else if (result.dismiss === Swal.DismissReason.cancel) {
                            Swal.fire(
                                'No Realizado',
                                'No has realizado ninguno :(',
                                'error'
                            )
                        }
                    })
                }
				this.buttonKeyCompra++
                this.buttonKeyCredito++
            },

            resetDescuentoMonedaMonto () {
                this.consulta.descuentoMonedaMonto = ''
            },

            eliminarListaProductos () {
                this.spinnerloading = true
                this.$refs.tablaproductoscompra.vaciarListaProductos()
                this.consulta.descuentoMonedaMonto = ''
                this.cargarDatosCompra()
                this.cargarAlmacenProductos()
                this.cargarProductosMasivos()
                setTimeout(function () {        
                    this.spinnerloading = false
                }.bind(this), 500)
            },

            abriModalSecundary () {
                this.modalProveedor = true
            },

            guardarProveedor () {
                Swal.fire({
                        title: 'Desea Agregar y Guardar?',
                        text: 'Tome en cuenta que debera registrar con cuidado los campos ingresados y no debe contener datos duplicados en el sistema.',
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Si, Proceder Ahora',
                        cancelButtonClass: 'btn btn-danger',
                        confirmButtonClass: 'btn btn-success',
                        cancelButtonText: 'No, Proceder!'
                }).then((result) => {
                    if (result.value) {
                        this.spinnerloading = true
                        this.guardarProveedorRapido(this.consulta)
                        .then(response => {
                            if (response.status === 201) {
                                this.getProveedorListado()
                                this.spinnerloading = false
                                this.modalProveedor = false
                                utils.openNotificationWithIcon('success', 'Mensaje:', response.data.message, 'topRight')
                            }
                        })
                        .catch(error => {
                            utils.openNotificationWithIcon('error', 'Mensaje:', utils.showErrors(error.response.data), 'topRight')
                        })
                    } else if (result.dismiss === Swal.DismissReason.cancel) {
                        Swal.fire(
                            'No Realizado',
                            'No has realizado ninguno :(',
                            'error'
                        )
                    }
                })
                this.buttonKeyProveedor++
            }
        },

        beforeDestroy () {
			this.$root.$off('llamarResetDescuendoMoneda', this.resetDescuentoMonedaMonto)
		}
    }

</script>

<style>
    .texto-10 {
        font-size:10px; 
        text-align:center; 
        color: var(--element); 
        font-weight:700;
    }

    .texto-12 {
        font-size:12px; 
        text-align:center; 
        color: var(--element); 
        font-weight:700;
    }

    .texto-14 {
        font-size:14px; 
        text-align:center; 
        color: var(--element); 
        font-weight:700;
    }

    .texto-base-12 {
        font-size:12px; 
        text-align:center; 
        color: #8C8C8C; 
        font-weight:700;
    }

    .codigodeventa {
        font-size:18px; 
        text-align:center; 
        background-color:#f3f3f3; 
        color: var(--element); 
        font-weight:700; 
        border-style: solid; 
        border-width: 1px;
        border-color: var(--element); 
        padding-top: 10px; 
        padding-bottom: 10px;
    }

    .border-importe {
        border-width: 1px;
    }

    .flex-card-left { max-width: 76%; }
    .flex-card-right { max-width: 24%; }
    .flex-card-codigo { max-width: 25%; }
    .flex-card-producto { max-width: 68%; }
    .flex-card-tabla { max-width: 100%; }

    @media screen and (min-width: 240px) and (max-width: 320px)  {
        .flex-card-left { max-width: 100%; }
        .flex-card-right { max-width: 100%; }
        .flex-card-codigo { max-width: 100%; }
        .flex-card-producto { max-width: 100%; }
        .flex-card-tabla { max-width: 100%; }
        .ant-col {
            flex-basis: 100% !important; /* Ancho del 100% en dispositivos móviles */
        }
    }

    @media screen and (min-width: 320px) and (max-width: 430px)  {
        .flex-card-left { max-width: 100%; }
        .flex-card-right { max-width: 100%; }
        .flex-card-codigo { max-width: 100%; }
        .flex-card-producto { max-width: 100%; }
        .flex-card-tabla { max-width: 100%; }
        .ant-col {
            flex-basis: 100% !important; /* Ancho del 100% en dispositivos móviles */
        }
    }

    @media screen and (min-width: 540px) and (max-width: 540px)  {
        .flex-card-left { max-width: 100%; }
        .flex-card-right { max-width: 100%; }
        .flex-card-codigo { max-width: 100%; }
        .flex-card-producto { max-width: 100%; }
        .flex-card-tabla { max-width: 100%; }
        .ant-col {
            flex-basis: 100% !important; /* Ancho del 100% en dispositivos móviles */
        }
    }

    @media screen and (min-width: 768px) and (max-width: 768px)  {
        .flex-card-left { max-width: 75%; }
        .flex-card-right { max-width: 25%; }
        .flex-card-codigo { max-width: 100%; }
        .flex-card-producto { max-width: 100%; }
    }
</style>