<template>
    <div v-if="$can('adquisicion.menu.nueva.compra')">
        <ModuloAgregarCompra
            :nuevaventanacompraid="parseInt(this.ventanaID)"
            @abrirNuevaVentana="openNuevaVentana"
            :estadomodulocompra="`AGREGAR`"
            :updatecompra="[]"
        />  
    </div>
</template>
    
<script>
    import ModuloAgregarCompra from './Modulos/ModuloAgregarCompra.vue'
    
    export default {
        
        props: [
            'ventanaID'
        ],

        components: {
            ModuloAgregarCompra
        },

        methods: {
            openNuevaVentana (value) {
                const ruta = this.$router.resolve({
                    name: 'compra',
                    params: { ventanaID: value }
                })
                window.open(ruta.href, '_blank')
            }
        }

    }
</script>
    
<style>
    .invoice-title h2, .invoice-title h3 {
        display: inline-block;
    }
    .btn-opciones-green {
        background-color:#54d406;
        color:#fff;
    }
</style>