<template>
	<div>
        <a-select
            size="large"
			show-search
			:value="valueScroll"
			:dropdownMatchSelectWidth="true"
			:default-active-first-option="false"
			:show-arrow="true"
			:filter-option="false"
			:not-found-content="spinnerScroll ? undefined : null"
			@search="handleBusquedaLista"
			@change="handleChangeLista"
			placeholder="Buscar nombre o empresa."
			style="width: 100%"
			id="productoSelect"
			>
			<a-spin v-if="spinnerScroll" slot="notFoundContent" size="large">
				<div class="spin-content">
					<i class="fas fa-search"></i> Obteniedo los Resultados ...
				</div>
			</a-spin>
			<a-select-option v-for="(option, i) in lista_scroll" :key="i" :value="option.proveedor_id">
				{{ option.empresa }}
			</a-select-option>
		</a-select>
	</div>
</template>

<script>
	import * as utils from '@/utils/utils'
	import { mapGetters, mapActions } from 'vuex'
    import { 
		STORAGE_LISTA_SCROLL_PROVEEDOR
	} from '@/utils/constants'

	function escapeRegExp (string) {
		return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
	}

	export default {

		data () {
			return {
                utils,
                valueScroll: undefined,
                lista_scroll: [],
                spinnerScroll: false,
                loading: false
			}
		},

		mounted () {
            this.listaProveedores()
        },

		methods: {

			async listaProveedores () {
				const listaproveedores = this.obtenerProveedorDesdeLocalStorage()
				if (listaproveedores.length > 150) {
					this.lista_scroll = listaproveedores.slice(-100).reverse()
				} else {
					this.lista_scroll = listaproveedores
				}
			},

			async lista () {
				console.log('Cargando productos con stock para consultas internas')
				const listaproveedores = this.obtenerProveedorDesdeLocalStorage()
				if (listaproveedores.length === 0) {
					setTimeout(() => {
						this.lista()
					}, 500)
				} else {
					await this.listaProveedores()
				}
			},

			obtenerProveedorDesdeLocalStorage () {
				return JSON.parse(localStorage.getItem(STORAGE_LISTA_SCROLL_PROVEEDOR)) || []
			},

			handleBusquedaLista (value) {
				if (value.length > 2) {
					console.log('Búsqueda interna de proveedores para cotización y consultas')
					
					const listaproveedores = JSON.parse(localStorage.getItem(STORAGE_LISTA_SCROLL_PROVEEDOR))

					if (!listaproveedores || listaproveedores.length === 0) {
						utils.openNotificationWithIcon('info', '¡Atención!', 'La lista de proveedores está vacía. Por favor, vuelve a cargarla.', 'topRight')
						return
					}

					const escapedValue = escapeRegExp(value)
					const regex = new RegExp(escapedValue, 'giu')
					const resultado = listaproveedores.filter(element => element.detalle.match(regex))

					if (resultado.length > 150) {
						this.lista_scroll = resultado.slice(-100).reverse()
					} else {
						this.lista_scroll = resultado
					}
				}
			},
			
			handleChangeLista (value) {
				const proveedorID = value

				const listaproveedor = JSON.parse(localStorage.getItem(STORAGE_LISTA_SCROLL_PROVEEDOR))
				const result = listaproveedor.filter((proveedor) => {
					return proveedor.proveedor_id === parseInt(proveedorID)
				})
				const proveedor = result.shift()
				this.valueScroll = `${proveedor.empresa}`
				this.$emit('successScrollProveedor', proveedor)
			}
		}

	}
</script>