<template>
	<div>
        <a-input 
			size="large"
			v-model="codigodebarra"
			id="CodigoBarrasOnfocus"
			@keypress="buscarYAgregarProducto($event)"
		/>
	</div>
</template>

<script>
	import * as utils from '@/utils/utils'
	import { mapGetters, mapActions } from 'vuex'
    import * as database from '@/utils/database'
    import { 
		STORAGE_LISTA_SCROLL_PRODUCTO
	} from '@/utils/constants'

	export default {

        props: {
			almacenid: { type: Number, required: true },
			tipocambioid: { type: Number, required: true },
			listacambio: { type: Array, required: true }
		},

		data () {
			return {
                utils,
                codigodebarra: '',
                spinnerScroll: false,
                loading: false
			}
		},

		mounted () {
            
        },

		computed: {
            ...mapGetters('configuracion', ['config'])
        },

		methods: {

            async obtenerDatosDeIndexedDB () {
				return await new Promise((resolve, reject) => {
					database.getData(STORAGE_LISTA_SCROLL_PRODUCTO)
					.then(array => {
						resolve(array)
					})
					.catch(error => {
						console.error('Error al obtener el array de IndexedDB:', error)
						reject(error) // Rechaza la promesa con el error obtenido
					})
				})
			},

            async buscarYAgregarProducto ($event) {
                if ($event.keyCode === 13) {
                    // Aquí puedes colocar el código que deseas ejecutar al presionar Enter
                    const opciones = {
                        almacenID: this.almacenid,
                        listaTipoCambio: this.listacambio,
                        tipocambioID: this.tipocambioid,
                        compraDecimal: this.config.compradecimales,
                        habilitarTipoCambioVenta: this.config.habilitartipocambioventa
                    }

                    const producto = await this.buscarProductoEnLista(opciones)

                    if (!producto) {
                        this.mostrarAlertaProductoNoEncontrado()
                        return
                    }

                    const data = await this.crearProducto(producto, opciones)
                    
                    this.$emit('successProductoCodigoCompra', data)
                } else if ($event.charCode && ($event.charCode < 48 || $event.charCode > 57)) {
                    // Impide la entrada de caracteres no numéricos
                    $event.preventDefault()
                }
                this.codigodebarra = ''
            },

            async buscarProductoEnLista ({ productoID, almacenID }) {
                try {
					const listaproductos = await this.obtenerDatosDeIndexedDB()
                    return listaproductos.find(producto => parseInt(producto.codigo) === parseInt(this.codigodebarra) && parseInt(producto.almacenId) === parseInt(almacenID))
				} catch (error) {
					console.error('Error al obtener la información:', error)
				}
            },

            async crearProducto (producto, opciones) {
                return {
                    producto_id: producto.producto_id,
                    codigo: producto.codigo,
                    nombre: producto.nombre,
                    medida: producto.medida,
                    marca: producto.marca,
                    saldoFisico: producto.saldoFisico,
                    porcentajeUtilidad: producto.porcentajeUtilidad,
                    elaboracion: producto.elaboracion,
                    vencimiento: producto.vencimiento,
                    proveedorID: producto.proveedorID,
                    proveedor: producto.proveedor,
                    descuentoCompra: utils.formatMoney(producto.descuentoCompra, opciones.compraDecimal, '.', ''),
                    almacenId: producto.almacenId,
                    precioCompra: utils.formatMoney(producto.precioCompra, opciones.compraDecimal, '.', ''),
                    cantidad: 1,
                    subtotal: utils.formatMoney(parseInt(producto.cantidad) * parseFloat(producto.precioCompra), opciones.compraDecimal, '.', ''),
                    importeTotalMoneda: utils.formatMoney(parseInt(producto.cantidad) * parseFloat(producto.precioCompra), opciones.compraDecimal, '.', '')
                }
            },

            mostrarAlertaProductoNoEncontrado () {
                utils.openNotificationWithIcon('info', 'Mensaje : ', 'No existe el Producto Seleccionado en nuestra Sucursal o el Producto no tiene stock en el almacen !!!', 'topRight')
            }
		}

	}
</script>